<template>
  <div>
    <el-card v-loading="isLoading" shadow="always" style="max-height: 700px">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="start"
              ><h4>{{ $route.name }}</h4></el-row
            ></el-col
          >
          <el-col :md="16" :sm="16" :xs="14">
            <el-row type="flex" justify="end">
              <el-col :md="24">
                <el-row :gutter="4" type="flex" justify="end" align="center">
                  <el-button
                    type="primary"
                    icon="el-icon-s-operation"
                    class="mb-0"
                    size="medium"
                    @click="openFilterCashierModal()"
                  ></el-button>
                  <!--<el-button
                  type="primary"
                  class="mb-0"
                  icon="el-icon-download"
                  :loading="isLoadingDownloadButton"
                  size="medium"
                  @click="downloadReport()"
                ></el-button>-->
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
      <vue-apex-charts
        :key="chartKey"
        type="area"
        v-if="OrdersSeries?.length"
        :options="getAreaOptions()"
        :series="AllSeries"
        height="500"
      ></vue-apex-charts>
      <el-empty v-else></el-empty>
    </el-card>

    <el-card v-loading="isLoading" class="mt-1">
      <el-table :data="OrdersData">
        <el-table-column
          v-for="(a, id) in OrdersColumns"
          :key="a"
          :label="
            hasValidNumber(a[0]) ? dateFormatter.format(new Date(a[0])) : a[0]
          "
          :prop="`${id}`"
          :formatter="
            (r) =>
              hasValidNumber(r[id])
                ? currencyFormatter.format(Number(r[id]))
                : r[id]
          "
        ></el-table-column>
      </el-table>
    </el-card>

    <filter-orders-modal
      :showModal="showFilterOrdersModal"
      :filters="filterOptions"
      @close-modal="showFilterOrdersModal = false"
      @update-filters="updateFilters"
    ></filter-orders-modal>
  </div>
</template>

<script>
import FilterOrdersModal from "./modals/FilterOrdersModal.vue";
import VueApexCharts from "vue3-apexcharts";
import OrderService from "../services/orders";
import { uuid } from "vue-uuid";
import {
  dateFormatter,
  currencyFormatter,
  numberFormatter,
} from "../utils/formatters";

export default {
  name: "CashierCloseResults",
  components: {
    FilterOrdersModal,
    VueApexCharts,
  },
  data: () => ({
    showFilterOrdersModal: false,
    orders: null,
    chartKey: uuid.v4(),
    filterOptions: {
      status: ["completed", "paid", "finalized"],
      delivered_at_start: getFirstDayInMonth(),
      delivered_at_end: new Date(),
    },
    isLoadingDownloadButton: false,
    isLoading: false,
    dateFormatter,
    currencyFormatter,
    numberFormatter,
  }),
  mounted() {
    this.fetchOrders();
  },
  computed: {
    Orders() {
      return this.orders || [];
    },
    OrdersColumns() {
      return [["empresa"], ...(this.OrdersSeries?.[0]?.data || [])];
    },
    OrdersData() {
      return this.OrdersSeries.map((c, i) => [
        c.name,
        ...(this.OrdersSeries?.[i]?.data?.map((_) => _[1]) || []),
      ]);
    },
    CashierDonuts() {
      const sum = [];
      for (let c = 0; c < this.OrdersSeries.length; c++)
        sum.push(this.sumTotalSold(c));

      return sum;
    },
    PrivateOrdersSeries() {
      const orders = this.orderByFirms();
      for (let c in orders) {
        const _ = orders[c].reduce((t, e) => {
          if (!this.isPublicSell(e)) {
            if (t[ensureItsZeroHour(e.delivered_at)])
              t[ensureItsZeroHour(e.delivered_at)].push(e);
            else t[ensureItsZeroHour(e.delivered_at)] = [e];
          }
          return t;
        }, {});

        orders[c] = Object.entries(_)?.map((e) => ({
          total_sells: e[1].reduce((t, e) => (t += Number(e.total_amount)), 0),
          delivered_at: new Date(e[0].slice(0, 11)?.replace("T", " ")),
        }));
      }

      return Object.entries(orders)?.map((e) => ({
        name: "PRAÇA",
        data: e[1]?.map((e) => [
          new Date(e.delivered_at).valueOf(),
          Number(e.total_sells),
        ]),
      }));
    },
    PublicOrdersSeries() {
      const orders = this.orderByFirms();
      for (let c in orders) {
        const _ = orders[c].reduce((t, e) => {
          if (this.isPublicSell(e)) {
            if (t[ensureItsZeroHour(e.delivered_at)])
              t[ensureItsZeroHour(e.delivered_at)].push(e);
            else t[ensureItsZeroHour(e.delivered_at)] = [e];
          }
          return t;
        }, {});

        orders[c] = Object.entries(_)?.map((e) => ({
          total_sells: e[1].reduce((t, e) => (t += Number(e.total_amount)), 0),
          delivered_at: new Date(e[0].slice(0, 11)?.replace("T", " ")),
        }));
      }

      return Object.entries(orders)?.map((e) => ({
        name: "LICITAÇÃO",
        data: e[1]?.map((e) => [
          new Date(e.delivered_at).valueOf(),
          Number(e.total_sells),
        ]),
      }));
    },
    AllSeries() {
      return [
        ...this.OrdersSeries,
        ...this.PublicOrdersSeries,
        ...this.PrivateOrdersSeries,
      ];
    },
    OrdersSeries() {
      const orders = this.orderByFirms();
      for (let c in orders) {
        const _ = orders[c].reduce((t, e) => {
          if (t[ensureItsZeroHour(e.delivered_at)])
            t[ensureItsZeroHour(e.delivered_at)].push(e);
          else t[ensureItsZeroHour(e.delivered_at)] = [e];
          return t;
        }, {});

        orders[c] = Object.entries(_)?.map((e) => ({
          total_sells: e[1].reduce((t, e) => (t += Number(e.total_amount)), 0),
          delivered_at: new Date(e[0].slice(0, 11)?.replace("T", " ")),
        }));
      }

      return Object.entries(orders)?.map((e) => ({
        name: e[0],
        data: e[1]?.map((e) => [
          new Date(e.delivered_at).valueOf(),
          Number(e.total_sells),
        ]),
      }));
    },
  },
  methods: {
    rebuildChart() {
      this.chartKey = uuid.v4();
    },
    isPublicSell(order) {
      return order?.client?.legal?.social_name?.includes("PREGAO");
    },
    // getDonutOptions() {
    //   return {
    //     chart: {
    //       height: 350,
    //       type: "donut",
    //       zoom: {
    //         enabled: false,
    //       },
    //       locales: [
    //         {
    //           name: "pt-br",
    //           options: {
    //             months: [
    //               "Janeiro",
    //               "Fevereiro",
    //               "Março",
    //               "Abril",
    //               "Maio",
    //               "Junho",
    //               "Julho",
    //               "Agosto",
    //               "Setembro",
    //               "Outubro",
    //               "Novembro",
    //               "Dezembro",
    //             ],
    //             shortMonths: [
    //               "Jan",
    //               "Fev",
    //               "Mar",
    //               "Abr",
    //               "Mai",
    //               "Jun",
    //               "Jul",
    //               "Ago",
    //               "Set",
    //               "Out",
    //               "Nov",
    //               "Dez",
    //             ],
    //             days: [
    //               "Domingo",
    //               "Segunda",
    //               "Terça",
    //               "Quarta",
    //               "Quinta",
    //               "Sexta",
    //               "Sábado",
    //             ],
    //             shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    //             toolbar: {
    //               exportToSVG: "Baixar SVG",
    //               exportToPNG: "Baixar PNG",
    //               exportToCSV: "Baixar CSV",
    //               menu: "Menu",
    //               selection: "Selecionar",
    //               selectionZoom: "Selecionar Zoom",
    //               zoomIn: "Aumentar",
    //               zoomOut: "Diminuir",
    //               pan: "Navegação",
    //               reset: "Reiniciar Zoom",
    //             },
    //           },
    //         },
    //       ],
    //       defaultLocale: "pt-br",
    //     },
    //     //colors: ["#0d84ff"],
    //     dataLabels: {
    //       enabled: false,
    //     },
    //     labels: Object.keys(this.orderByFirms()),
    //     series: this.CashierDonuts,
    //     legend: {
    //       formatter: (label, opts) =>
    //         `${label} ( ${this.currencyFormatter.format(
    //           this.sumTotalSold(opts.seriesIndex)
    //         )} )`,
    //     },
    //     theme: {
    //       mode: "light",
    //       palette: "palette0",
    //     },
    //     stroke: {
    //       curve: "smooth",
    //     },
    //     title: {
    //       text: "Participação no Resultado",
    //       align: "left",
    //     },
    //     /*grid: {
    //       row: {
    //         colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
    //         opacity: 1,
    //       },
    //     },*/
    //     xaxis: {
    //       type: "datetime",
    //     },
    //     yaxis: {
    //       labels: {
    //         formatter: (v) => this.currencyFormatter.format(Number(v) || 0),
    //       },
    //     },
    //   };
    // },
    getAreaOptions() {
      return {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          locales: [
            {
              name: "pt-br",
              options: {
                months: [
                  "Janeiro",
                  "Fevereiro",
                  "Março",
                  "Abril",
                  "Maio",
                  "Junho",
                  "Julho",
                  "Agosto",
                  "Setembro",
                  "Outubro",
                  "Novembro",
                  "Dezembro",
                ],
                shortMonths: [
                  "Jan",
                  "Fev",
                  "Mar",
                  "Abr",
                  "Mai",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Set",
                  "Out",
                  "Nov",
                  "Dez",
                ],
                days: [
                  "Domingo",
                  "Segunda",
                  "Terça",
                  "Quarta",
                  "Quinta",
                  "Sexta",
                  "Sábado",
                ],
                shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                toolbar: {
                  exportToSVG: "Baixar SVG",
                  exportToPNG: "Baixar PNG",
                  exportToCSV: "Baixar CSV",
                  menu: "Menu",
                  selection: "Selecionar",
                  selectionZoom: "Selecionar Zoom",
                  zoomIn: "Aumentar",
                  zoomOut: "Diminuir",
                  pan: "Navegação",
                  reset: "Reiniciar Zoom",
                },
              },
            },
          ],
          defaultLocale: "pt-br",
        },
        //colors: ["#0d84ff"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          formatter: (label, opts) =>
            `${label} ( ${this.currencyFormatter.format(
              this.sumTotalSold(opts.seriesIndex)
            )} )`,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Resultado de Vendas da Donau",
          align: "left",
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: (v) => this.currencyFormatter.format(Number(v) || 0),
          },
        },
      };
    },
    sumTotalSold(index) {
      const _ = this.AllSeries[index];

      if (_) return _?.data?.reduce((t, e) => (t += e[1]), 0);
      else return 0;
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    openFilterCashierModal() {
      this.showFilterOrdersModal = true;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.fetchOrders();
    },
    calculateResult(r) {
      return Number(Number(r.total_cashier) - Number(r.check_total)) || 0;
    },
    orderByFirms() {
      return {
        "COMERCIO DE CARNES DONAU EIRELI": JSON.parse(
          JSON.stringify(this.Orders)
        ),
      };
    },
    hasValidNumber(e) {
      return !isNaN(Number(e));
    },
    async fetchOrders() {
      this.isLoading = true;

      const { orders } = await OrderService().index(this.filterOptions);

      if (orders) this.orders = orders;

      this.isLoading = false;
      const self = this;
      setTimeout(() => self.rebuildChart(), 500);
    },
  },
};
const ensureItsZeroHour = (e) => e?.slice(0, 11);
const getFirstDayInMonth = () => {
  const _ = new Date();
  return new Date(_.getFullYear(), _.getMonth(), 1);
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
.is-negative {
  color: red !important;
}
.is-positive {
  color: blue !important;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary {
  color: #909399;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
}
.mt-1 {
  margin-top: 8px;
}
.summary-black {
  color: #333 !important;
}
</style>
